<template>
  <el-drawer
    :title="`${curInfo.advertiserName} -- 子账号列表`"
    :visible.sync="show"
    :before-close="handleClose"
    size="60%"
    append-to-body
  >
    <div class="operation">
      <el-button @click="refreshData" style="margin-right: 20px">刷新数据</el-button>
      <el-button type="primary" @click="addBound" style="margin-right: 20px"
        >新增关联</el-button
      >
      <el-input
        placeholder="账户名"
        v-model="params.advertiserName"
        clearable
        style="width: 300px"
        class="input-with-select margin-right-twentyFour"
        @keydown.native.enter="handlePageChange(1)"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handlePageChange(1)"
        ></el-button>
      </el-input>
      <el-select
        class="margin-right-twentyFour"
        style="width: 150px"
        v-model="params.accountChannelId"
        filterable
        placeholder="公众号"
        clearable
        @change="handlePageChange(1)"
      >
        <el-option
          v-for="item in officialList"
          :key="item.id"
          :value="item.id"
          :label="item.channelName"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span
            :style="
              item.status
                ? 'margin-left: 10px;color:#00bf8a'
                : 'margin-left: 10px;color:#fd5d5a'
            "
            >{{ item.status ? "绑定" : "解绑" }}</span
          >
          <span style="float: right; margin-left: 15px; font-size: 13px"
            >{{ item.platformStr }} - {{ item.uname }}</span
          >
        </el-option>
      </el-select>
      <el-select
        v-model="params.booksId"
        filterable
        remote
        clearable
        style="width: 150px"
        reserve-keyword
        placeholder="书籍名称"
        :remote-method="getBookList"
        @change="handlePageChange"
      >
        <el-option
          v-for="item in bookList"
          :key="item.id"
          :label="item.bookName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-tag style="color: #72767b; font-size: 12px; margin: 0 15px"
        >共{{ dataList.length }}条</el-tag
      >
    </div>
    <div class="custom-table">
      <el-table
        :data="dataList"
        v-loading="tableLoading"
        height="80vh"
        style="width: 100%"
      >
        <el-table-column prop="id" label="id" width="100"></el-table-column>
        <el-table-column prop="advertiserId" label="账户id" width="200"></el-table-column>
        <el-table-column prop="advertiserName" label="账户名称"></el-table-column>
        <el-table-column label="公众号">
          <template slot-scope="scope">
            <span v-if="'channelName' in scope.row">{{ scope.row.channelName }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-tag
              :class="{ 'bound-account': scope.row.status === 1 }"
              @click="changeBoundStatus($event, scope.row)"
              :type="getStyle(scope.row).type"
              >{{ getStyle(scope.row).name }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="书籍" width="250">
          <template slot-scope="scope">
            <span v-if="'bookName' in scope.row">{{ scope.row.bookName }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              class="button-small"
              @click="addChannelBound(scope.row)"
              >新增绑定</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-drawer
      title="添加绑定"
      size="720px"
      append-to-body
      :visible.sync="isShowBound"
      :before-close="
        () => {
          this.isShowBound = false;
          this.relationForm = {};
        }
      "
    >
      <el-form label-width="130px">
        <el-form-item label="账户">
          <el-select
            class="margin-right-twentyFour"
            style="width: 500px"
            v-model="chooseAccountList"
            multiple
            filterable
            :disabled="isSingle"
            placeholder="选择账户"
          >
            <el-option
              v-for="item in dataList"
              :key="item.id"
              :value="item.id"
              :label="item.advertiserName"
            >
              <span style="float: left">{{ item.advertiserName }}</span>
              <span
                :style="
                  item.status
                    ? 'margin-left: 10px;color:#00bf8a'
                    : 'margin-left: 10px;color:#fd5d5a'
                "
                >{{ item.status ? "绑定" : "解绑" }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公众号">
          <el-select
            class="margin-right-twentyFour"
            style="width: 500px"
            v-model="relationForm.accountChannelId"
            filterable
            placeholder="公众号"
          >
            <el-option
              v-for="item in officialList.filter(item=>item.status === 1)"
              :key="item.id"
              :value="item.id"
              :label="item.channelName"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span
                :style="
                  item.status
                    ? 'margin-left: 10px;color:#00bf8a'
                    : 'margin-left: 10px;color:#fd5d5a'
                "
                >{{ item.status ? "绑定" : "解绑" }}</span
              >
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="书籍名称">
          <el-select
            v-model="relationForm.booksId"
            filterable
            remote
            clearable
            @clear="getBookList"
            style="width: 500px"
            reserve-keyword
            placeholder="书籍名称"
            :remote-method="getBookList"
          >
            <el-option
              v-for="item in bookList"
              :key="item.id"
              :label="item.bookName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button
          @click="
            () => {
              this.isShowBound = false;
              this.relationForm = {};
            }
          "
          >取 消</el-button
        >
        <el-button type="primary" :loading="loadingButton" @click="handleSubmitRelation"
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </el-drawer>
</template>

<script>
import {
  getOceanChannel,
  syncOceanAccountByHand,
  addOceanLinkWithChannel,
} from "@/api/extend.js";
import { getBookList, unbindAd } from "@/api/account";
import { mapGetters } from "vuex";
export default {
  props: ["show", "curInfo"],
  data() {
    return {
      params: {},
      tableLoading: false,
      loadingFresh: false,
      dataList: [],
      bookList: [],
      isShowBound: false,
      currentChannel: {},
      relationForm: {},
      loadingButton: false,
      chooseAccountList: [],
      isSingle: false,
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  watch: {
    show(newV) {
      if (newV) {
        this.getSubAccount();
        this.getBookList();
      }
    },
  },
  mounted() {},
  methods: {
    refreshData() {
      this.getSubAccount();
    },
    getBookList(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    getSubAccount() {
      let { id } = this.curInfo;
      this.tableLoading = true;
      getOceanChannel({
        id,
        advertiserName: this.params.advertiserName,
        accountChannelId: this.params.accountChannelId,
        booksId: this.params.booksId,
      })
        .then((res) => {
          this.dataList = res;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handlePageChange(page) {
      this.getSubAccount();
    },
    getStyle(row) {
      let statusObj = {
        0: {
          name: "未接入",
          type: "warning",
        },
        1: {
          name: "已绑定",
          type: "success",
        },
        2: {
          name: "已解绑",
          type: "danger",
        },
      };
      return statusObj[row.status];
    },
    async addChannelBound(row) {
      this.currentChannel = row;
      this.chooseAccountList = [this.currentChannel.id];
      if ([1,2].includes(row.status)) {
         this.relationForm = {
           accountChannelId:row.accountChannelId,
           booksId:row.booksId
         }
         this.getBookById(row.booksId);
      }else{
        this.getBookList();
      }
     
        this.isSingle = true;
        this.isShowBound = true;
     
    },
    handleSubmitRelation() {
      if (!this.relationForm.booksId) {
        return this.$message.error("请选择书籍!");
      }
      if (!this.relationForm.accountChannelId) {
        return this.$message.error("请选择公众号!");
      }
      this.loadingButton = true;
      let tmpParams = this.chooseAccountList.map((item) => {
        return {
          accountChannelId: this.relationForm.accountChannelId,
          booksId: this.relationForm.booksId,
          id: item,
          marketingId:
            this.dataList.find((item2) => {
              return item2.id === item;
            }).marketingId || null,
        };
      });
      addOceanLinkWithChannel(tmpParams)
        .then(() => {
          this.$message.success("关联成功!");
          this.relationForm = {};
          this.isShowBound = false;
          this.getSubAccount();
        })
        .finally(() => {
          this.loadingButton = false;
        });
    },
    addBound() {
      this.isSingle = false;
      this.chooseAccountList = [];
      this.isShowBound = true;
    },
    changeBoundStatus(e, row) {
      let { marketingId, status } = row;
      if (status !== 1) return;
      this.$confirm("确定要解绑？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        unbindAd(marketingId).then(() => {
          this.$message.success("解绑成功!");
          this.getSubAccount();
        });
      });
    },
    handleClose() {
      this.$emit("update:show", false);
      this.relationForm = {};
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-drawer {
  .el-drawer__body {
    padding: 0 20px;
  }
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.bound-account {
  cursor: pointer;
}
.operation {
  line-height: 40px;
}
.dialog-footer {
  margin-left: 130px;
}
.custom-table {
  margin-top: 20px;
}
</style>
